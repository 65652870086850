export default {
  "/": {
    en: {
      title: "Development of systems for dental aligners companies and laboratories",
      desc:
        "Development of 3D models for manufacturing of Aligners, aesthetic mockups, as well as design a smile on customer's specifications. MeshAlign - digital solution for the modern orthodontics.",
      keys: "aligners, orthodontic treatment, digital smile design, treatment plan",
      url: "",
      img: require("./metaListAssets/home_page.jpg"),
    },
    ru: {
      title: "Разработка систем элайнеров для стоматологических компаний и зуботехнических лабораторий",
      desc:
        "Разработка 3D моделей для изготовления элайнеров, эстетических мокапов, а так же дизайна улыбки по заданию заказчика. MeshAlign — цифровое решение для современной ортодонтии. ",
      keys: "Элайнеры, ортодонтическое лечение, цифровой дизайн улыбки, план лечения",
      url: "",
      img: require("./metaListAssets/home_page.jpg"),
    },
  },
  "/classification": {
    en: {
      title: "Classification and treatment of pathologies orthodontic using techniques MeshAlign",
      desc:
        "Types of occlusion disturbance that can be corrected by applying treatment with aerators using the MeshAlign technique.",
      keys: "aligners, orthodontic treatment, digital smile design, treatment plan",
      url: "/classification",
      img: require("./metaListAssets/classification.jpg"),
    },
    ru: {
      title: "Классификация и лечение ортодонтических патологий с помощью методики MeshAlign",
      desc: "Типы нарушения окклюзии, которые можно исправить, применив лечение элайнерами по методике MeshAlign.",
      keys: "Скученность зубов, глубокий прикус",
      url: "/classification",
      img: require("./metaListAssets/classification.jpg"),
    },
  },
  "/libraryes": {
    en: {
      title: "Library with 2D and 3D teeth styles for creating mockups and smile designs",
      desc:
        "The dental libraries, developed by MeshAlign to create mockups and smile designs. Allows to exactly repeat the smile design in the future modeling of restorations.",
      keys: "Teeth libraries, smile design",
      url: "/libraryes",
      img: require("./metaListAssets/teeth_libraries.jpg"),
    },
    ru: {
      title: "Библиотека с 2D и 3D фасонами зубов для создания мокапов и дизайнов улыбок",
      desc:
        "Библиотеки зубов, разработанная специалистами MeshAlign для создания мокапов и дизайнов улыбок. Позволяет точно повторить дизайн улыбки в будущей моделировке реставраций.",
      keys: "Формы зубов, дизайн улыбки",
      url: "/libraryes",
      img: require("./metaListAssets/teeth_libraries.jpg"),
    },
  },
  "/classification/one-side-angles-class-II": {
    en: {
      title: "One side Angle's Class II",
      desc: "Visualization of orthodontic treatment with aligners: One side Angle's Class II",
      keys: "One side Angle's Class",
      url: "/classification/one-side-angles-class-II",
      img: require("./metaListAssets/One_side_Angle's_Class_II.jpg"),
    },
    ru: {
      title: "II класс по Энглю с одной стороны",
      desc: "Визуализация ортодонтического лечения элайнерами: II класс по Энглю с одной стороны",
      keys: "класс по Энглю с одной стороны",
      url: "/classification/one-side-angles-class-II",
      img: require("./metaListAssets/One_side_Angle's_Class_II.jpg"),
    },
  },
  "/classification/angles-class-II-division-I": {
    en: {
      title: "Angle's Class II Division I",
      desc: "Visualization of orthodontic treatment with aligners: Angle's Class II Division I",
      keys: "Angle's Class II ",
      url: "/classification/angles-class-II-division-I",
      img: require("./metaListAssets/Angle's_Class_II_Division_I.jpg"),
    },
    ru: {
      title: "II класс по Энглю. Подкласс I",
      desc: "Визуализация ортодонтического лечения элайнерами: II класс по Энглю. Подкласс I",
      keys: "II класс по Энглю",
      url: "/classification/one-side-angles-class-II",
      img: require("./metaListAssets/Angle's_Class_II_Division_I.jpg"),
    },
  },
  "/classification/angles-class-II-division-II": {
    en: {
      title: "Angle's Class II Division II",
      desc: "Visualization of orthodontic treatment with aligners: Angle's Class II Division II",
      keys: "Division II",
      url: "/classification/angles-class-II-division-II",
      img: require("./metaListAssets/Angle's_Class_II_Division_II.jpg"),
    },
    ru: {
      title: "II класс по Энглю. Подкласс II",
      desc: "Визуализация ортодонтического лечения элайнерами: II класс по Энглю. Подкласс II",
      keys: "Подкласс II",
      url: "/classification/angles-class-II-division-II",
      img: require("./metaListAssets/Angle's_Class_II_Division_II.jpg"),
    },
  },
  "/classification/one-side-angles-class-III": {
    en: {
      title: "One side Angle's Class III",
      desc: "Visualization of orthodontic treatment with aligners: One side Angle's Class III",
      keys: "Angle's Class III",
      url: "/classification/one-side-angles-class-III",
      img: require("./metaListAssets/One_side_Angle's_Class_III.jpg"),
    },
    ru: {
      title: "III класс по Энглю с одной стороны",
      desc: "Визуализация ортодонтического лечения элайнерами: III класс по Энглю с одной стороны",
      keys: "III класс по Энглю",
      url: "/classification/one-side-angles-class-III",
      img: require("./metaListAssets/One_side_Angle's_Class_III.jpg"),
    },
  },
  "/classification/overbite": {
    en: {
      title: "Overbite (deep bite)",
      desc: "Visualization of orthodontic treatment with aligners: Overbite (deep bite)",
      keys: "Overbite",
      url: "/classification/overbite",
      img: require("./metaListAssets/Overbite_(deep_bite).jpg"),
    },
    ru: {
      title: "Overbite (глубокий прикус)",
      desc: "Визуализация ортодонтического лечения элайнерами: Overbite (глубокий прикус)",
      keys: "глубокий прикус",
      url: "/classification/overbite",
      img: require("./metaListAssets/Overbite_(deep_bite).jpg"),
    },
  },
  "/classification/overjet": {
    en: {
      title: "Overjet (sagittal fissure)",
      desc: "Visualization of orthodontic treatment with aligners: Overjet (sagittal fissure)",
      keys: "Overjet",
      url: "/classification/overjet",
      img: require("./metaListAssets/Overjet_(sagittal_fissure).jpg"),
    },
    ru: {
      title: "Overjet (сагиттальная щель)",
      desc: "Визуализация ортодонтического лечения элайнерами: Overjet (сагиттальная щель)",
      keys: "сагиттальная щель",
      url: "/classification/overjet",
      img: require("./metaListAssets/Overjet_(sagittal_fissure).jpg"),
    },
  },
  "/libraryes/expression": {
    en: {
      title: "Library with 2D and 3D Expression tooth styles",
      desc: "Expression teeth libraries for smile design and restoration modeling",
      keys: "3D teeth design, dental library",
      url: "/libraryes/expression",
      img: require("./metaListAssets/Tooth_styles_Expression.jpg"),
    },
    ru: {
      title: "Библиотека с 2D и 3D фасонами зубов Expression",
      desc: "Библиотеки зубов Expression для дизайна улыбки и моделирования реставраций",
      keys: "3D дизайн зубов, библиотека зубов",
      url: "/libraryes/expression",
      img: require("./metaListAssets/Tooth_styles_Expression.jpg"),
    },
  },
  "/libraryes/pogarskiy-design": {
    en: {
      title: "Library with 2D and 3D tooth styles Pogarskiy Design Library",
      desc: "Pogarskiy Design Library teeth libraries for smile design and restoration modeling",
      keys: "3D shape of teeth",
      url: "/libraryes/pogarskiy-design",
      img: require("./metaListAssets/Tooth_styles_PDL.jpg"),
    },
    ru: {
      title: "Библиотека с 2D и 3D фасонами зубов Pogarskiy Design Library",
      desc: "Pogarskiy Design Library - библиотека зубов для дизайна улыбки и моделирования реставраций",
      keys: "формы зубов",
      url: "/libraryes/pogarskiy-design",
      img: require("./metaListAssets/Tooth_styles_PDL.jpg"),
    },
  },
  "/classification/intruded-tooth": {
    en: {
      title: "Intruded tooth",
      desc: "Visualization of orthodontic treatment with aligners: Intruded tooth",
      keys: "Intruded",
      url: "/classification/intruded-tooth",
      img: require("./metaListAssets/Intruded_tooth.jpg"),
    },
    ru: {
      title: "Интрузированный зуб",
      desc: "Визуализация ортодонтического лечения элайнерами: Интрузированный зуб",
      keys: "Интрузированный",
      url: "/classification/intruded-tooth",
      img: require("./metaListAssets/Intruded_tooth.jpg"),
    },
  },
  "/classification/violation-tooth-angulation": {
    en: {
      title: "Violation tooth angulation",
      desc: "Visualization of orthodontic treatment with aligners: Violation tooth angulation",
      keys: "angulation",
      url: "/classification/violation-tooth-angulation",
      img: require("./metaListAssets/Violation_tooth_angulation.jpg"),
    },
    ru: {
      title: "Нарушение ангуляции зуба",
      desc: "Визуализация ортодонтического лечения элайнерами: Нарушение ангуляции зуба",
      keys: "ангуляция",
      url: "/classification/violation-tooth-angulation",
      img: require("./metaListAssets/Violation_tooth_angulation.jpg"),
    },
  },
  "/classification/small-multiple-violations": {
    en: {
      title: "Small multiple violations of the position of the teeth",
      desc:
        "Visualization of orthodontic treatment with aligners: Small multiple violations of the position of the teeth",
      keys: "position of the teeth",
      url: "/classification/small-multiple-violations",
      img: require("./metaListAssets/Small_multiple_violations_of_the.jpg"),
    },
    ru: {
      title: "Небольшие множественные нарушения положения зубов",
      desc: "Визуализация ортодонтического лечения элайнерами: Небольшие множественные нарушения положения зубов",
      keys: "положения зубов",
      url: "/classification/small-multiple-violations",
      img: require("./metaListAssets/Small_multiple_violations_of_the.jpg"),
    },
  },
  "/classification/incorrect-inclination-of-the-tooth": {
    en: {
      title: "Incorrect inclination of the tooth",
      desc: "Visualization of orthodontic treatment with aligners: Incorrect inclination of the tooth",
      keys: "inclination of the tooth",
      url: "/classification/incorrect-inclination-of-the-tooth",
      img: require("./metaListAssets/Incorrect_inclination_of_the_tooth.jpg"),
    },
    ru: {
      title: "Неправильный наклон зуба",
      desc: "Визуализация ортодонтического лечения элайнерами: Неправильный наклон зуба",
      keys: "наклон зуба",
      url: "/classification/incorrect-inclination-of-the-tooth",
      img: require("./metaListAssets/Incorrect_inclination_of_the_tooth.jpg"),
    },
  },
  "/classification/openbite": {
    en: {
      title: "Open bite",
      desc: "Visualization of orthodontic treatment with aligners: Openbite",
      keys: "bite",
      url: "/classification/openbite",
      img: require("./metaListAssets/Openbite.jpg"),
    },
    ru: {
      title: "Открытый прикус",
      desc: "Визуализация ортодонтического лечения элайнерами: Открытый прикус",
      keys: "прикус",
      url: "/classification/openbite",
      img: require("./metaListAssets/Openbite.jpg"),
    },
  },
};
