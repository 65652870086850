import { OBTAIN_PROFILE_DATA, START_OBTAIN_PROFILE_DATA, OBTAIN_PROFILE_ERROR, TOGGLE_TOPBAR } from "../actions";

const initialState = {
  isLoading: true,
  id: null,
  account_type: "",
  avatar: "",
  name: "",
  clinic: "",
  phone_number: "",
  country: "",
  address: "",
  additional_phone_number: "",
  theme: "",
  language: "",
  send_email_notifications: false,

  errors: [],
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_TOPBAR:
      return { ...state, isTopBar: action.payload.isTopBar };
    // case TOGGLE_SWITCH_PROFILE_CONTENT:
    //   return { ...state, isSwitch: action.payload.isSwitch, type: action.payload.type };
    // case START_GET_PROFILE:
    // case TEST_LOGIN:
    //   return { ...state, account_type: action.payload };
    // case TEST_LOGOUT:
    //   return { ...state };
    // case TOGGLE_SWITCH_PROFILE_CONTENT:
    //   return { ...state, isSwitch: action.payload.isSwitch, type: action.payload.type };
    case START_OBTAIN_PROFILE_DATA:
      return {
        ...state,
        isLoading: true,
        errors: [],
      };
    case OBTAIN_PROFILE_DATA:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
        errors: [],
      };
    case OBTAIN_PROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
}
