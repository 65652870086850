import { API } from "../modules/Api.js";
import { closeSocket } from "../modules/Socket";
// import { obtainProfileData } from "./profileAction";
export const SET_AUTH = "SET_AUTH";
export const START_LOGIN = "START_LOGIN";
export const START_FINISH_REGISTER = "START_FINISH_REGISTER";
export const OBTAIN_LOGIN_DATA = "OBTAIN_LOGIN_DATA";
export const OBTAIN_FINISH_DATA = "OBTAIN_FINISH_DATA";
export const OBTAIN_LOGIN_ERROR = "OBTAIN_LOGIN_ERROR";

// Login
function startLogin() {
  return { type: START_LOGIN };
}

function obtainLoginData() {
  return { type: OBTAIN_LOGIN_DATA };
}

function obtainLoginError(error) {
  return {
    type: OBTAIN_LOGIN_ERROR,
    payload: error,
  };
}

export function login(email, password, successCallback) {
  return (dispatch) => {
    dispatch(startLogin());

    let loginData = {
      email: email,
      password: password,
    };

    return API.post("auth/login", loginData).then(
      () => {
        dispatch(obtainLoginData());
        successCallback();
      },
      (error) => {
        alert("Wrong email or password");
        dispatch(obtainLoginError(error));
      },
    );
  };
}

// Logout
export function logout() {
  return (dispatch) => {
    localStorage.clear();
    closeSocket();
    dispatch(setAuth());
  };
}

export function setAuth(force = false) {
  return {
    type: SET_AUTH,
    payload: !force ? !!localStorage.getItem(process.env.REACT_APP_AUTH_HEADER) : false,
  };
}
