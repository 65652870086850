import {
  TOGGLE_CONTACT_US,
  TOGGLE_COOKIE_POLICY,
  TOGGLE_TERMS_OF_USE,
  TOGGLE_TERMS_CONDITIONS,
  TOGGLE_CHANGE_EMAIL,
  TOGGLE_CHANGE_PASSWORD,
  TOGGLE_IMAGE_PREVIEW_DIALOG,
  TOGGLE_DELETE_CARD_OBJECT,
  TOGGLE_DELETE_VISUALIZATION,
  TOGGLE_DELETE_APPOINTMENT,
  TOGGLE_ADD_VIDEO,
  TOGGLE_ADD_IMAGE,
  TOGGLE_EDIT_IMAGE,
  TOGGLE_EDIT_VIDEO,
  TOGGLE_SHARE_VISUALIZATION,
  TOGGLE_CONFIRM_CLOSE,
  TOGGLE_CHANGE_STATUS,
  TOGGLE_TUTORIAL_PATIENTS,
  TOGGLE_TUTORIAL_COMPANY_LIST,
  TOGGLE_TUTORIAL_VISUALISATION,
  TOGGLE_TUTORIAL_PROFILE,
  TOGGLE_TUTORIAL_PROJECT_CREATION,
  TOGGLE_TUTORIAL_ORDER_CREATION,
  TOGGLE_TUTORIAL_SMILE_DESIGN,
  TOGGLE_TUTORIAL_AESTHETIC_MOCUP,
  TOGGLE_SOURCE_REQUIREMENTS,
  TOGGLE_PHOTO_PROTOCOL,
  TOGGLE_USING_ELASTICS,
  TOGGLE_PLACING_ATTACHMENTS,
  TOGGLE_REDUCTION,
  TOGGLE_SURFACES,
  TOGGLE_RECOMENDATIONS,
} from "../actions";

const initialState = {
  isContactUs: false,
  isToggleDate: false,
  isToggleDateTreatment: false,
  isPhotoProtocol: false,

  isCookiePolicy: false,
  isTermsConditions: false,
  isTermsOfUse: false,
  isUsingElastics: false,
  isPlacingAttachments: false,
  isReduction: false,
  isSurfaces: false,
  isRecomendations: false,
  isChangeEmail: false,
  isChangePassword: false,
  isUserId: null,
  isDeleteVisualization: false,
  cardId: null,
  isObjectId: null,
  isObjectStatusId: null,
  isDeleteCardObject: false,
  isAppointmentId: null,
  isChangeStatus: false,
  isAddVideo: false,
  isAddImage: false,
  isEditImage: false,
  isEditVideo: false,
  isShareVisualization: false,
  isConfirmClose: false,
  isTutorialPatients: false,
  isTutorialCompanyList: false,
  isTutorialVisualisation: false,
  isTutorialProfile: false,
  isTutorialProjectCreation: false,
  isTutorialOrderCreation: false,
  isTutorialOrderSmileDesign: false,
  isTutorialOrderAestheticMocup: false,
  isSourceRequirements: false,
};

export default function dialogReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_USING_ELASTICS:
      return {
        ...state,
        isUsingElastics: action.payload.isShow,
      };
    case TOGGLE_PLACING_ATTACHMENTS:
      return {
        ...state,
        isPlacingAttachments: action.payload.isShow,
      };
    case TOGGLE_REDUCTION:
      return {
        ...state,
        isReduction: action.payload.isShow,
      };
    case TOGGLE_SURFACES:
      return {
        ...state,
        isSurfaces: action.payload.isShow,
      };
    case TOGGLE_RECOMENDATIONS:
      return {
        ...state,
        isRecomendations: action.payload.isShow,
      };
    case TOGGLE_SHARE_VISUALIZATION:
      return {
        ...state,
        isShareVisualization: action.payload.isShow,
      };
    case TOGGLE_EDIT_VIDEO:
      return {
        ...state,
        isEditVideo: action.payload.isShow,
      };
    case TOGGLE_EDIT_IMAGE:
      return {
        ...state,
        isEditImage: action.payload.isShow,
      };
    case TOGGLE_PHOTO_PROTOCOL:
      return {
        ...state,
        isPhotoProtocol: action.payload.isShow,
      };
    case TOGGLE_ADD_IMAGE:
      return {
        ...state,
        isAddImage: action.payload.isShow,
      };
    case TOGGLE_ADD_VIDEO:
      return {
        ...state,
        isAddVideo: action.payload.isShow,
      };
    case TOGGLE_DELETE_VISUALIZATION:
      return {
        ...state,
        isDeleteVisualization: action.payload.isShow,
        isUserId: action.payload.isUserId,
      };
    case TOGGLE_DELETE_APPOINTMENT:
      return {
        ...state,
        isDeleteAppointment: action.payload.isShow,
        isAppointmentId: action.payload.isAppointmentId,
        cardId: action.payload.cardId,
      };
    case TOGGLE_CONTACT_US:
      return {
        ...state,
        isContactUs: action.payload.isShow,
      };

    case TOGGLE_COOKIE_POLICY:
      return {
        ...state,
        isCookiePolicy: action.payload.isShow,
      };
    case TOGGLE_TERMS_OF_USE:
      return {
        ...state,
        isTermsOfUse: action.payload.isShow,
      };
    case TOGGLE_TERMS_CONDITIONS:
      return {
        ...state,
        isTermsConditions: action.payload.isShow,
      };
    case TOGGLE_CHANGE_EMAIL:
      return {
        ...state,
        isChangeEmail: action.payload.isShow,
      };
    case TOGGLE_CHANGE_PASSWORD:
      return {
        ...state,
        isChangePassword: action.payload.isShow,
      };
    case TOGGLE_CONFIRM_CLOSE:
      return {
        ...state,
        isConfirmClose: action.payload.isShow,
      };
    case TOGGLE_IMAGE_PREVIEW_DIALOG:
      return {
        ...state,
        isImagePreview: action.payload.isShow,
        imagePreviewArray: action.payload.imagePreviewArray,
        imagePreviewIndex: action.payload.imagePreviewIndex,
      };
    case TOGGLE_DELETE_CARD_OBJECT:
      return {
        ...state,
        isDeleteCardObject: action.payload.isShow,
        cardId: action.payload.cardId,
        isObjectId: action.payload.isObjectId,
      };
    case TOGGLE_CHANGE_STATUS:
      return {
        ...state,
        isChangeStatus: action.payload.isShow,
        isObjectStatusId: action.payload.isObjectStatusId,
        cardId: action.payload.cardId,
      };
    case TOGGLE_TUTORIAL_PATIENTS:
      return {
        ...state,
        isTutorialPatients: action.payload.isShow,
      };
    case TOGGLE_TUTORIAL_COMPANY_LIST:
      return {
        ...state,
        isTutorialCompanyList: action.payload.isShow,
      };
    case TOGGLE_TUTORIAL_VISUALISATION:
      return {
        ...state,
        isTutorialVisualisation: action.payload.isShow,
      };
    case TOGGLE_TUTORIAL_PROFILE:
      return {
        ...state,
        isTutorialProfile: action.payload.isShow,
      };
    case TOGGLE_TUTORIAL_PROJECT_CREATION:
      return {
        ...state,
        isTutorialProjectCreation: action.payload.isShow,
      };
    case TOGGLE_TUTORIAL_ORDER_CREATION:
      return {
        ...state,
        isTutorialOrderCreation: action.payload.isShow,
      };
    case TOGGLE_TUTORIAL_SMILE_DESIGN:
      return {
        ...state,
        isTutorialOrderSmileDesign: action.payload.isShow,
      };
    case TOGGLE_TUTORIAL_AESTHETIC_MOCUP:
      return {
        ...state,
        isTutorialOrderAestheticMocup: action.payload.isShow,
      };
    case TOGGLE_SOURCE_REQUIREMENTS:
      return {
        ...state,
        isSourceRequirements: action.payload.isShow,
      };
    default:
      return state;
  }
}
