import { API } from "../modules/Api.js";
import Logger from "../modules/Logger";
export const TOGGLE_TOPBAR = "TOGGLE_TOPBAR";

export const switchTopBar = isTopBar => dispatch => {
  return API.put("auth/profile", {
    theme: isTopBar,
  }).then(
    response => {
      Logger.info(response);
      dispatch(toggleTopBar(isTopBar));
    },
    error => {
      Logger.info(error);
    },
  );
};

function toggleTopBar(isTopBar) {
  return {
    type: TOGGLE_TOPBAR,
    payload: { isTopBar },
  };
}
