import {
  SET_CARDS_USER,
  SET_CARD_SENDING,
  SET_CARDS_LIST_CANCEL_TOKEN,
  SET_3D_STL,
  START_OBTAIN_CARDS_LIST,
  OBTAIN_CARDS_LIST_DATA,
  OBTAIN_CARDS_LIST_ERROR,
  TOGGLE_CARD_EDITOR,
  TOGGLE_CARD_FINISH,
  TOGGLE_CARD_PREVIEW,
  TOGGLE_SHARE_PROJECT,
  TOGGLE_ORDER_TREATMENT_PLAN,
  TOGGLE_ORDER_TREATMENT_FINISH,
  TOGGLE_TREATMENT_FINISH,
  TOGGLE_TREATMENT_PLAN,
  TOGGLE_STL_DATA,
  TOGGLE_WINDOW_THUMBNAIL,
  RESET_WINDOW_THUMBNAIL_DATA,
  SET_CARD_PREVIEW_DATA,
  SET_CARD_PREVIEW_DATA_LIST,
  RESET_CARD_PREVIEW,
} from "../actions";

const initialState = {
  cardsUser: null,
  isCardSending: false,
  cardsList: [],
  cardsListError: [],
  cardsListNextCursor: null,
  cardsListCancelToken: null,
  cardsListIsLoading: true,

  isCardEditor: false,
  isEditCardId: null,
  isCardFinish: false,
  isCardIdFinish: null,
  isCardPreview: false,

  isCardIdPreview: null,
  isShareProject: false,
  isShareId: null,
  isOrderTreatment: false,
  isCardIdOrderTreatment: null,
  isOrderId: null,
  isOrderTreatmentFinish: false,
  isTreatmentFinish: false,
  isPayId: null,
  treatmentPlanPaymentItem: null,

  billData: [],

  isTreatment: false,
  isCardIdTreatment: null,
  isTreatmentId: null,

  isPatient3dSliderDialog: false,
  patient3dSliderDialog: null,

  currentView: "",
  stlData: [],
  isOpen: false,
  isMini: false,

  // Card preview Data
  cardPreviewData: {},
  cardPreviewDataList: [],
};

export default function cardReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CARDS_USER:
      return { ...state, cardsUser: action.payload };
    case SET_CARD_SENDING:
      return { ...state, isCardSending: action.payload };
    case SET_CARDS_LIST_CANCEL_TOKEN:
      return { ...state, cardsListCancelToken: action.payload };
    case START_OBTAIN_CARDS_LIST:
      return { ...state, cardsListIsLoading: true };
    case OBTAIN_CARDS_LIST_DATA:
      return {
        ...state,
        cardsListIsLoading: false,
        cardsList: action.payload.data,
        cardsListNextCursor: action.payload.nextCursor,
        cardsListError: [],
      };
    case OBTAIN_CARDS_LIST_ERROR:
      return {
        ...state,
        cardsListIsLoading: false,
        cardsListError: action.payload,
      };
    case TOGGLE_CARD_EDITOR:
      return {
        ...state,
        isCardEditor: action.payload.isShow,
        isEditCardId: action.payload.isEditCardId,
      };
    case TOGGLE_CARD_FINISH:
      return {
        ...state,
        isCardFinish: action.payload.isShow,
        isCardIdFinish: action.payload.cardId,
      };
    case TOGGLE_CARD_PREVIEW:
      return {
        ...state,
        isCardPreview: action.payload.isShow,
        isCardIdPreview: action.payload.cardId,
      };
    case RESET_CARD_PREVIEW:
      return {
        ...state,
        cardPreviewData: {},
        cardPreviewDataList: [],
      };
    case TOGGLE_SHARE_PROJECT:
      return {
        ...state,
        isShareProject: action.payload.isShow,
        isShareId: action.payload.isShareId,
      };
    case TOGGLE_ORDER_TREATMENT_PLAN:
      return {
        ...state,
        isOrderTreatment: action.payload.isShow,
        isOrderId: action.payload.orderId,
        isCardIdOrderTreatment: action.payload.cardId,
      };
    case TOGGLE_ORDER_TREATMENT_FINISH:
      return {
        ...state,
        isPaymentCardId: action.payload.isPaymentCardId,
        isPayId: action.payload.isPayId,
        isOrderTreatmentFinish: action.payload.isShow,
      };
    case TOGGLE_TREATMENT_FINISH:
      return {
        ...state,
        isPaymentCardId: action.payload.isPaymentCardId,
        isPayId: action.payload.isPayId,
        isTreatmentFinish: action.payload.isShow,
        billData: action.payload.billData,
        treatmentPlanPaymentItem: action.payload.item,
      };
    case TOGGLE_TREATMENT_PLAN:
      return {
        ...state,
        isTreatment: action.payload.isShow,
        isTreatmentId: action.payload.treatmentId,
        isCardIdTreatment: action.payload.cardId,
      };
    case TOGGLE_STL_DATA:
      return {
        ...state,
        isPatient3dSliderDialog: action.payload.isShow,
        patient3dSliderDialog: action.payload.itemId,
        isCardId3dSlider: action.payload.cardId,
      };
    case TOGGLE_WINDOW_THUMBNAIL:
      return { ...state, isOpen: action.payload, isMini: false };
    case RESET_WINDOW_THUMBNAIL_DATA:
      return { ...state, currentView: "", stlData: [] };
    case SET_3D_STL:
      return { ...state, currentView: "stlPreview", stlData: action.payload };

    case SET_CARD_PREVIEW_DATA:
      return { ...state, cardPreviewData: action.payload };
    case SET_CARD_PREVIEW_DATA_LIST:
      return { ...state, cardPreviewDataList: action.payload };
    default:
      return state;
  }
}
