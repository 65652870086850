import { TOGGLE_TOPBAR } from "../actions";

const initialState = {
  isTopBar: "",
};

export default function topBarReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_TOPBAR:
      return { ...state, isTopBar: action.payload.isTopBar };
    default:
      return state;
  }
}
