import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en.json";
import ru from "./locales/ru.json";
// the translations

i18n
  // .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: process.env.REACT_APP_LANG ? process.env.REACT_APP_LANG : "en",
    debug: false,
    resources: {
      en: { translation: en },
      ru: { translation: ru },
    },
    load: "unspecific",
    whitelist: ["en", "ru"],
    fallbackLng: ["en"],
    nsSeparator: "::",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
