import { TOGGLE_FILE_UPLOAD, ADD_FILE_PROGRESS, SET_FILE_PROGRESS, REMOVE_FILE_PROGRESS } from "../actions";

const initialState = {
  isOpen: false,
  fileUploadList: [],
  fileUploadError: "",
};

export default function fileUploadReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_FILE_UPLOAD:
      return {
        ...state,
        isOpen: action.payload,
      };
    case ADD_FILE_PROGRESS:
      return {
        ...state,
        isOpen: true,
        fileUploadList: [...state.fileUploadList, action.payload],
      };
    case SET_FILE_PROGRESS: {
      const fileList = [...state.fileUploadList];
      const index = state.fileUploadList.findIndex(item => item.fileName === action.payload.fileName);
      fileList[index].progress = action.payload.progress;
      return { ...state, fileUploadList: fileList };
    }
    case REMOVE_FILE_PROGRESS: {
      const fileUploadList = state.fileUploadList.filter(item => item.fileName !== action.payload);
      return {
        ...state,
        fileUploadList,
      };
    }
    default:
      return state;
  }
}
