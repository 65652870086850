import io from "socket.io-client";
import Logger from "./Logger";
import {
  addSingleCard,
  getSidebarContacts,
  getSingleChatBoxMessage,
  getSupportList,
  getUpcomingAppointments,
  getUpcomingAppointmentsNow,
  getAppointmentInCard,
  getUserUnreadNotifications,
  updateSingleCard,
} from "../actions";
let socket, socketStore;

export const getSocket = () => socket;
export const closeSocket = () => (socket ? socket.close() : null);
export const createSocketConnection = (store) => {
  socketStore = store;

  if (socket) {
    socket.close();
  }
  socket = io(`${process.env.REACT_APP_WSS}/notifications`);

  attachServiceCommands();

  return socket;
};

export const reconnectSocket = (userId) => {
  if (socket) {
    socket.close();
  }
  socket = io(`${process.env.REACT_APP_WSS}/notifications?id=${userId}`);

  attachAppCommands();

  return socket;
};

const attachAppCommands = () => {
  socket.on("message", (data) => {
    Logger.info("new socket message", data);
    // TODO here we need some optimization to set only one contact if exists
    const accountType = socketStore.getState().profile.account_type;
    const currentUser = socketStore.getState().chat.currentUser;
    if (accountType === "owner") {
      socketStore.dispatch(getSidebarContacts(1));
      socketStore.dispatch(getSingleChatBoxMessage(data.message_id));
    }
    if (currentUser === data.sender_id) {
      socketStore.dispatch(getSingleChatBoxMessage(data.message_id));
      // console.log("get message ", data.message_id);
    }
    Logger.info(socketStore);
  });

  socket.on("notifications", (data) => {
    Logger.info("Socket on support", data);
    switch (data.type) {
      case "SupportRequestCreate":
        // console.log("support notifications");
        socketStore.dispatch(getSupportList(1));
        break;
      default:
      // console.log("none notifications", data);
    }
  });

  // Cards event
  socket.on("cards", (data) => {
    // console.log("New socket cards", data);
    switch (data.type) {
      case "CardUpdate":
      case "AlignersOrderCreate":
      case "TreatmentPlanCreate":
      case "SetUpDataCreate":
      case "TreatmentPlanPay":
      case "AlignersOrderPay":
        socketStore.dispatch(getUserUnreadNotifications(1));
        socketStore.dispatch(updateSingleCard(data.card_id));
        break;
      case "TreatmentPlanConfirm":
        socketStore.dispatch(getUserUnreadNotifications(1));
        socketStore.dispatch(updateSingleCard(data.id));
        break;
      case "AppointmentCreate":
        socketStore.dispatch(getUpcomingAppointments(1));
        socketStore.dispatch(getAppointmentInCard(data.card_id));
        socketStore.dispatch(getUpcomingAppointmentsNow(1));
        break;
      case "CardCreate":
        socketStore.dispatch(addSingleCard(data.card_id));
        socketStore.dispatch(getUserUnreadNotifications(1));
        break;
      default:
      // console.log("Uncatched socket appointments event", data);
    }
  });
};

export const socketEmmit = (type, payload) => {
  if (socket) {
    socket.emit(type, payload);
  }
};

const attachServiceCommands = () => {
  // Service commands
  socket.on("connect", () => {
    Logger.info("SOCKET CONNECTION connected (openned) => ", socket);
  });
  socket.on("disconnect", () => {
    Logger.info("SOCKET CONNECTION disconnect => ", socket ? socket.connected : null);
  });
  socket.on("connect_error", (error) => {
    Logger.info("SOCKET connect_error => ", JSON.stringify(error));
  });
  socket.on("connect_timeout", (timeout) => {
    Logger.info("SOCKET connect_timeout => ", timeout);
  });
  socket.on("error", (error) => {
    Logger.info("SOCKET error => ", error);
  });
  socket.on("ping", () => {
    Logger.info("SOCKET ping");
  });
  socket.on("pong", (latency) => {
    Logger.info("SOCKET pong => ", latency);
  });

  // Reconnect
  socket.on("reconnect", (attempt) => {
    Logger.info("SOCKET reconnect => ", attempt);
  });
  socket.on("reconnect_attempt", (res) => {
    Logger.info("SOCKET reconnect_attempt => ", res);
  });
  socket.on("reconnecting", (attempt) => {
    Logger.info("SOCKET reconnecting => ", attempt);
  });
  socket.on("reconnect_error", (error) => {
    Logger.info("SOCKET reconnect_error => ", JSON.stringify(error));
  });
  socket.on("reconnect_failed", () => {
    Logger.info("SOCKET reconnect_failed => ");
  });
};
