import { SET_SNACKBAR_NOTIFICATION, SET_CARD_ADD } from "../actions";

const initialState = {
  isShow: false,
  isCardAdd: false,
  notification: "",
};

export default function snackbarNotificationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SNACKBAR_NOTIFICATION:
      return { ...state, isShow: !!action.payload, notification: action.payload };
    case SET_CARD_ADD:
      return { ...state, isCardAdd: action.payload };
    default:
      return state;
  }
}
