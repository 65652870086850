import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { createSocketConnection } from "./modules/Socket";

const initialState = {};

const enhancers = [];
if (process.env.NODE_ENV === "development") {
  // Devtools error
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(thunk), ...enhancers);

const store = createStore(rootReducer, initialState, composedEnhancers);

createSocketConnection(store);

export default store;
