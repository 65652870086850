import {
  OBTAIN_NOTIFICATIONS_DATA,
  OBTAIN_NOTIFICATIONS_OBJECT_DATA,
  OBTAIN_NOTIFICATIONS_UNREAD_COUNT_DATA,
  OBTAIN_NOTIFICATIONS_ERROR,
  OBTAIN_NOTIFICATIONS_DATA_LOADING,
  OBTAIN_NOTIFICATIONS_OBJECT_CANCEL_TOKEN,
  OBTAIN_NOTIFICATIONS_CANCEL_TOKEN,
  SET_USER_UNREAD_NOTIFICATIONS,
} from "../actions";

const initialState = {
  notificationsList: [],
  notificationsListObject: [],
  notificationsListError: [],
  notificationsListCursor: null,

  notificationsListCount: 0,
  notificationsListUnreadCount: 0,

  notificationsListObjectCancelToken: null,
  notificationsListOCancelToken: null,
  notificationsListLoading: false,

  userUnreadNotifications: 0,
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case OBTAIN_NOTIFICATIONS_DATA:
      return {
        ...state,
        notificationsList: action.payload.data,
        notificationsListCursor: action.payload.nextCursor,
        notificationsListCount: action.payload.count,
        notificationsListError: [],
      };
    case OBTAIN_NOTIFICATIONS_OBJECT_DATA:
      return {
        ...state,
        notificationsListObject: action.payload.data,
      };
    case OBTAIN_NOTIFICATIONS_UNREAD_COUNT_DATA:
      return {
        ...state,
        notificationsListUnreadCount: action.payload.countUnread,
      };
    case OBTAIN_NOTIFICATIONS_OBJECT_CANCEL_TOKEN:
      return { ...state, notificationsListObjectCancelToken: action.payload };
    case OBTAIN_NOTIFICATIONS_CANCEL_TOKEN:
      return { ...state, notificationsListCancelToken: action.payload };
    case OBTAIN_NOTIFICATIONS_ERROR:
      return { ...state, notificationsListError: action.payload };
    case OBTAIN_NOTIFICATIONS_DATA_LOADING:
      return { ...state, notificationsListLoading: action.payload };

    case SET_USER_UNREAD_NOTIFICATIONS:
      return { ...state, userUnreadNotifications: action.payload };
    default:
      return state;
  }
}
