import { combineReducers } from "redux";

import loginReducer from "./login";
import profileReducer from "./profile";
import cardReducer from "./card";
import dialogReducer from "./dialog";
import { createDialogsReducer } from "./dialogs";
import contactReducer from "./contact";
import topBarReducer from "./topBar";
import chatReducer from "./chat";
import supportReducer from "./support";
import invoicesReducer from "./invoices";
import notificationsReducer from "./notifications";
import fileUploadReducer from "./fileUpload";
import appointmentsReducer from "./appointments";
import snackbarNotificationReducer from "./snackbarNotification";
import upcomingAppointmentsReducer from "./upcomingAppointments";

export default combineReducers({
  login: loginReducer,
  profile: profileReducer,
  card: cardReducer,
  dialog: dialogReducer,
  contact: contactReducer,
  topBar: topBarReducer,
  chat: chatReducer,
  support: supportReducer,
  invoices: invoicesReducer,
  notifications: notificationsReducer,
  fileUpload: fileUploadReducer,
  appointments: appointmentsReducer,
  snackbarNotification: snackbarNotificationReducer,
  upcomingAppointments: upcomingAppointmentsReducer,
  ...createDialogsReducer(),
});
