import React, { lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { reconnectSocket } from "./modules/Socket";
import { OpenRoute, PrivateRoute } from "./Routes";
import "./App.scss";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import MetaTags from "./components/MetaTags";
import { useTranslation } from "react-i18next";
import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";

const LoginLayout = lazy(() => import(/* webpackChunkName: "LoginLayout" */ "./layouts/LoginLayout"));
const LoginPage = lazy(() => import(/* webpackChunkName: "LoginLayout" */ "./layouts/LoginPage"));
const RegistrationPage = lazy(() => import(/* webpackChunkName: "LoginLayout" */ "./layouts/RegistrationPage"));
const ConfirmPage = lazy(() => import(/* webpackChunkName: "ConfirmPage" */ "./layouts/ConfirmPage"));
const ProjectPage = lazy(() => import(/* webpackChunkName: "ProjectPage" */ "./layouts/ProjectPage"));
const MainLayout = lazy(() => import(/* webpackChunkName: "MainLayout" */ "./layouts/MainLayout"));
const ClinicalProtocolsPage = lazy(() =>
  import(/* webpackChunkName: "ClinicalProtocolsPage" */ "./layouts/ClinicalProtocolsPage"),
);

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
});

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.on("languageChanged", (lng) => {
      let redirectList = { en: "https://meshalign.com", ru: "https://ru.meshalign.com" };
      if (redirectList.hasOwnProperty(lng)) {
        return (window.location = redirectList[lng] + window.location.pathname);
      }
    });
  }, [i18n]);

  useEffect(() => {
    const analytics = Analytics({
      app: "Meshalign",
      plugins: [googleAnalytics({ trackingId: "UA-171390954-1" })],
    });
    /* Track a page view */
    analytics.page();
  }, []);

  const { userId, isAuth } = useSelector(({ profile, login }) => ({ userId: profile.id, isAuth: login.isAuth }));

  useEffect(() => {
    reconnectSocket(userId);
  }, [userId]);

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          <MetaTags />
          <Switch>
            <PrivateRoute path="/profile" component={MainLayout} auth={isAuth} />
            <PrivateRoute path="/patients" component={MainLayout} auth={isAuth} />
            <PrivateRoute path="/companies-list" component={MainLayout} auth={isAuth} />
            <PrivateRoute path="/visualizations" component={MainLayout} auth={isAuth} />
            <PrivateRoute path="/clinical-protocols" component={MainLayout} auth={isAuth} />
            <PrivateRoute path="/tutorial" component={MainLayout} auth={isAuth} />
            <PrivateRoute path="/support" component={MainLayout} auth={isAuth} />
            <PrivateRoute path="/finance" component={MainLayout} auth={isAuth} />
            <PrivateRoute path="/appointments" component={MainLayout} auth={isAuth} />
            <PrivateRoute path="/contacts" component={MainLayout} auth={isAuth} />

            <OpenRoute path="/clinical-protocol/:protocol" component={ClinicalProtocolsPage} />

            <OpenRoute exact path="/share_project/:token" component={ProjectPage} />
            <OpenRoute exact path="/login" component={LoginPage} />
            <OpenRoute exact path="/change_email/:token" component={ConfirmPage} />
            <OpenRoute exact path="/sign_up/:token" component={RegistrationPage} />
            <OpenRoute path="/" component={LoginLayout} />
            <Redirect from="*" to="/" />
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default App;
