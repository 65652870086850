export const SET_SNACKBAR_NOTIFICATION = "SET_SNACKBAR_NOTIFICATION";
export const SET_CARD_ADD = "SET_CARD_ADD";

function setSnackbarNotification(notification) {
  return {
    type: SET_SNACKBAR_NOTIFICATION,
    payload: notification,
  };
}

export const toggleSnackbarNotification = notification => dispatch => {
  dispatch(setSnackbarNotification(notification));
};

export function setCardAdd(isAdd) {
  return {
    type: SET_CARD_ADD,
    payload: isAdd,
  };
}

