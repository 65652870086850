import { SUPPORT_LIST, SUPPORT_LIST_ERROR, SUPPORT_NOTIFIC } from "../actions";

const initialState = {
  supportList: [],
  supportListNextCursor: null,
  supportListErrors: [],
  supportNotificCount: null,
};

export default function supportReducer(state = initialState, action) {
  switch (action.type) {
    case SUPPORT_LIST:
      return {
        ...state,
        supportList: action.payload.data,
        supportListNextCursor: action.payload.nextCursor,
      };
    case SUPPORT_LIST_ERROR:
      return { ...state, supportListErrors: action.payload };
    case SUPPORT_NOTIFIC:
      return { ...state, supportNotificCount: action.payload };
    default:
      return state;
  }
}
