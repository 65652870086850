import { API } from "../modules/Api";
import Logger from "../modules/Logger";
export const SUPPORT_LIST = "SUPPORT_LIST";
export const SUPPORT_LIST_ERROR = "SUPPORT_LIST_ERROR";
export const SUPPORT_NOTIFIC = "SUPPORT_NOTIFIC";

function supportList(data, nextCursor) {
  return {
    type: SUPPORT_LIST,
    payload: { data, nextCursor },
  };
}

function supportListError(error) {
  return {
    type: SUPPORT_LIST_ERROR,
    payload: error,
  };
}

export function supportNotific(notificCount) {
  return {
    type: SUPPORT_NOTIFIC,
    payload: notificCount,
  };
}

export const clearSupportList = () => (dispatch) => {
  dispatch(supportList([], null));
};

export const getSupportList = (page) => (dispatch, getState) => {
  if (page === 1) {
    dispatch(clearSupportList());
  }
  const nextCursor = getState().support.supportListNextCursor;
  return API.get("/support/list", {
    params: {
      cursor: nextCursor,
    },
  }).then(
    (response) => {
      const currentsupportList = page !== 1 ? [...getState().support.supportList] : [];
      const supportListResults = [...currentsupportList, ...response.data.results];

      dispatch(supportList(supportListResults, response.data.next));
      dispatch(supportNotific(response.data.unread));
    },
    (error) => {
      Logger.info(error);
      dispatch(supportListError(error));
    },
  );
};

export const supportUnread = () => (dispatch) => {
  return API.post("/contact_us/markasreadall").then(() => {
    dispatch(supportNotific(0));
  });
};
