import { API } from "../modules/Api.js";
import { CancelToken } from "axios";
import Logger from "../modules/Logger";
import { toggleDeleteVisualization, toggleDeleteCardObject, getUserUnreadNotifications } from "../actions";
export const SET_CARDS_USER = "SET_CARDS_USER";
export const SET_CARD_SENDING = "SET_CARD_SENDING";
export const SET_CARDS_LIST_CANCEL_TOKEN = "SET_CARDS_LIST_CANCEL_TOKEN";
export const START_OBTAIN_CARDS_LIST = "START_OBTAIN_CARDS_LIST";
export const OBTAIN_CARDS_LIST_DATA = "OBTAIN_CARD_LIST_DATA";
export const OBTAIN_CARDS_LIST_ERROR = "OBTAIN_CARD_LIST_ERROR";
export const TOGGLE_CARD_EDITOR = "TOGGLE_CARD_EDITOR";
export const TOGGLE_CARD_FINISH = "TOGGLE_CARD_FINISH";
export const TOGGLE_CARD_PREVIEW = "TOGGLE_CARD_PREVIEW";
export const TOGGLE_SHARE_PROJECT = "TOGGLE_SHARE_PROJECT";
export const TOGGLE_ORDER_TREATMENT_PLAN = "TOGGLE_ORDER_TREATMENT_PLAN";
export const TOGGLE_ORDER_TREATMENT_FINISH = "TOGGLE_ORDER_TREATMENT_FINISH";
export const TOGGLE_TREATMENT_FINISH = "TOGGLE_TREATMENT_FINISH";
export const TOGGLE_TREATMENT_PLAN = "TOGGLE_TREATMENT_PLAN";
export const TOGGLE_STL_DATA = "TOGGLE_STL_DATA";
export const SET_3D_STL = "SET_3D_STL";
export const TOGGLE_WINDOW_THUMBNAIL = "TOGGLE_WINDOW_THUMBNAIL";
export const RESET_WINDOW_THUMBNAIL_DATA = "RESET_WINDOW_THUMBNAIL_DATA";

// Card preview data
export const RESET_CARD_PREVIEW = "RESET_CARD_PREVIEW";
export const SET_CARD_PREVIEW_DATA_LOADING = "SET_CARD_PREVIEW_DATA_LOADING";
export const SET_CARD_PREVIEW_DATA = "SET_CARD_PREVIEW_DATA";
export const SET_CARD_PREVIEW_DATA_LIST = "SET_CARD_PREVIEW_DATA_LIST";

export function setCardsUser(userId) {
  return {
    type: SET_CARDS_USER,
    payload: userId,
  };
}

function setCardSending(isSending) {
  return {
    type: SET_CARD_SENDING,
    payload: isSending,
  };
}

function cardsList(data, nextCursor) {
  return {
    type: OBTAIN_CARDS_LIST_DATA,
    payload: { data, nextCursor },
  };
}

function cardsListError(error) {
  return {
    type: OBTAIN_CARDS_LIST_ERROR,
    payload: error,
  };
}

function setCardsListCancelToken(token) {
  return {
    type: SET_CARDS_LIST_CANCEL_TOKEN,
    payload: token,
  };
}

function startObtainCardsList() {
  return {
    type: START_OBTAIN_CARDS_LIST,
  };
}

export function toggleCardEditor({ isShow, isEditCardId }) {
  return {
    type: TOGGLE_CARD_EDITOR,
    payload: { isShow, isEditCardId },
  };
}

export function toggleCardFinish(isShow, cardId) {
  return {
    type: TOGGLE_CARD_FINISH,
    payload: { isShow, cardId },
  };
}

function setCardPreviewDialog(isShow, cardId) {
  return {
    type: TOGGLE_CARD_PREVIEW,
    payload: { isShow, cardId },
  };
}
function resetCardPreview() {
  return {
    type: RESET_CARD_PREVIEW,
  };
}
export const toggleCardPreview = ({ isShow, cardId }) => (dispatch) => {
  // Reset card data after close dialog
  if (!isShow) {
    dispatch(resetCardPreview());
  }
  dispatch(setCardPreviewDialog(isShow, cardId));
};

export function toggleShareProject({ isShow, isShareId }) {
  return {
    type: TOGGLE_SHARE_PROJECT,
    payload: { isShow, isShareId },
  };
}
export function toggleOrderTreatmentPlan({ isShow, orderId, cardId }) {
  return {
    type: TOGGLE_ORDER_TREATMENT_PLAN,
    payload: { isShow, orderId, cardId },
  };
}
export function toggleOrderTreatmentFinish({ isShow, isPayId, isPaymentCardId }) {
  return {
    type: TOGGLE_ORDER_TREATMENT_FINISH,
    payload: { isShow, isPayId, isPaymentCardId },
  };
}

export function toggleTreatmentFinish({ isShow, isPayId, isPaymentCardId, billData, item }) {
  return {
    type: TOGGLE_TREATMENT_FINISH,
    payload: { isShow, isPayId, isPaymentCardId, billData, item },
  };
}

export function toggleTreatmentPlan({ isShow, treatmentId, cardId }) {
  return {
    type: TOGGLE_TREATMENT_PLAN,
    payload: { isShow, treatmentId, cardId },
  };
}

export function toggleStlData({ isShow, itemId, cardId }) {
  return {
    type: TOGGLE_STL_DATA,
    payload: { isShow, itemId, cardId },
  };
}
function resetWindowThumbnail() {
  return {
    type: RESET_WINDOW_THUMBNAIL_DATA,
  };
}

function set3dStlModels(models) {
  return {
    type: SET_3D_STL,
    payload: models,
  };
}

function setWindowThumbnail(isShow) {
  return {
    type: TOGGLE_WINDOW_THUMBNAIL,
    payload: isShow,
  };
}

export const toggleWindowThumbnail = (isShow) => (dispatch) => {
  if (!isShow) {
    dispatch(resetWindowThumbnail());
  }
  dispatch(setWindowThumbnail(isShow));
};

export const stl3dPreviewAddSet = (stlFiles) => (dispatch, getState) => {
  dispatch(set3dStlModels([...stlFiles]));
};

export const stl3dPreviewRemove = (name) => (dispatch, getState) => {
  const currentModels = getState().card.stlData;
  dispatch(set3dStlModels([...currentModels.filter((el) => el.displayName !== name)]));
};

export const getCardsList = (page, search) => (dispatch, getState) => {
  dispatch(startObtainCardsList());

  const currentCancelToken = getState().card.cardsListCancelToken;
  if (currentCancelToken) {
    currentCancelToken();
  }
  const currentUserId = getState().card.cardsUser;

  const nextCursor = page !== 1 ? getState().card.cardsListNextCursor : null;

  return API.get("/card", {
    cancelToken: new CancelToken((token) => {
      dispatch(setCardsListCancelToken(token));
    }),
    params: { cursor: nextCursor, id: currentUserId, search: search },
  }).then(
    (response) => {
      const currentCardsList = page !== 1 ? [...getState().card.cardsList] : [];
      const cardsListResults = [...currentCardsList, ...response.data.results];
      dispatch(cardsList(cardsListResults, response.data.next));
    },
    (error) => {
      Logger.info(error);
      dispatch(cardsListError(error));
    },
  );
};

export const createCard = (cardData) => (dispatch, getState) => {
  dispatch(setCardSending(true));
  API.post("/card", cardData).then(
    (response) => {
      dispatch(toggleCardEditor({ isShow: false, isEditCardId: null }));
      // const updatedCards = [{ ...response.data }, ...getState().card.cardsList];
      // const currentCursor = getState().card.cardsListNextCursor;
      // dispatch(cardsList(updatedCards, currentCursor));
      dispatch(toggleCardFinish(true, response.data.id));
      dispatch(setCardSending(false));
      // const currentUser = getState().profile.id;
      // dispatch(push(`/profile/${currentUser}/projects`));
    },
    (error) => {
      dispatch(setCardSending(false));
    },
  );
};

export const updateCard = (cardId, cardData) => (dispatch, getState) => {
  dispatch(setCardSending(true));
  API.patch(`/card/${cardId}`, cardData).then(
    (response) => {
      dispatch(toggleCardEditor({ isShow: false, isEditCardId: null }));
      const updatedCards = [
        ...getState().card.cardsList.map((el) => {
          if (el.id === response.data.id) {
            return { ...el, ...response.data };
          }
          return el;
        }),
      ];
      const currentCursor = getState().card.cardsListNextCursor;
      dispatch(cardsList(updatedCards, currentCursor));
      dispatch(setCardSending(false));
      // const currentUser = getState().profile.id;
      // dispatch(push(`/profile/${currentUser}/projects`));
    },
    (error) => {
      dispatch(setCardSending(false));
    },
  );
};

export const addSingleCard = (cardId) => (dispatch, getState) => {
  API.get(`/card/${cardId}`).then((response) => {
    const updatedCards = [{ ...response.data }, ...getState().card.cardsList];
    const currentCursor = getState().card.cardsListNextCursor;
    dispatch(cardsList(updatedCards, currentCursor));
  });
};

// Update single card and also update card in preview if it exists
export const updateSingleCard = (cardId) => (dispatch, getState) => {
  API.get(`/card/${cardId}`).then((response) => {
    const updatedCards = [
      ...getState().card.cardsList.map((el) => {
        if (el.id === response.data.id) {
          return { ...el, ...response.data };
        }
        return el;
      }),
    ];
    const currentCursor = getState().card.cardsListNextCursor;
    dispatch(cardsList(updatedCards, currentCursor));

    // This method is heavy but useful
    if (getState().card.isCardPreview) {
      dispatch(getCardPreviewData(cardId));
      dispatch(getCardPreviewDataList(cardId));
    }
  });
};

export const makePrepayment = () => (dispatch, getState) => {
  const isPayId = getState().card.isPayId;
  const isPaymentCardId = getState().card.isPaymentCardId;
  API.post(`/card/${isPaymentCardId}/pay/${isPayId}`).then(
    (response) => {
      dispatch(updateSingleCard(isPaymentCardId));
      dispatch(toggleOrderTreatmentFinish({ isShow: false, isPayId: null, isPaymentCardId: null }));
      dispatch(getUserUnreadNotifications(1));
    },
    (error) => {},
  );
};

export const deleteCard = (id) => (dispatch, getState) => {
  return API.delete(`/card/${id}`).then(
    (response) => {
      Logger.info(response.data);
      dispatch(getCardsList(1));
      dispatch(toggleCardPreview({ isShow: false }));
      dispatch(toggleDeleteVisualization({ isShow: false, isUserId: null }));
    },
    (error) => {
      Logger.info(error);
    },
  );
};

export const deleteCardObject = (id, cardId) => (dispatch, getState) => {
  return API.delete(`/card/${cardId}/object/${id}`).then(
    (response) => {
      Logger.info(response.data);
      dispatch(toggleDeleteCardObject({ isShow: false, cardId: null, isObjectId: null }));
    },
    (error) => {
      Logger.info(error);
    },
  );
};

export const createOrderTreatmentPlan = (data, cardId) => (dispatch, getState) => {
  dispatch(setCardSending(true));
  API.post("/aligner_order", data).then(
    (response) => {
      dispatch(updateSingleCard(cardId));
      dispatch(setCardSending(false));
      dispatch(toggleOrderTreatmentPlan({ isShow: false, orderId: null, cardId: null }));
      dispatch(
        toggleOrderTreatmentFinish({
          isShow: true,
          isPayId: response.data.id,
          isPaymentCardId: cardId,
        }),
      );
      // dispatch(push(`/profile/${userId}/projects`));
      // dispatch(setCardAdd(true));
    },
    () => {
      dispatch(setCardSending(false));
    },
  );
};

export const updateOrderTreatmentPlan = (cardId, orderId, data) => (dispatch, getState) => {
  dispatch(setCardSending(true));
  API.patch(`/aligner_order/${orderId}`, data).then(
    (response) => {
      dispatch(updateSingleCard(cardId));
      dispatch(toggleOrderTreatmentPlan({ isShow: false, orderId: null, cardId: null }));
      dispatch(setCardSending(false));
      // dispatch(push(`/profile/${userId}/projects`));
      // dispatch(setCardAdd(true));
    },
    () => {
      dispatch(setCardSending(false));
    },
  );
};
export const createTreatmentPlan = (data, cardId) => (dispatch, getState) => {
  dispatch(setCardSending(true));
  API.post("/treatment_plan", data).then(
    (response) => {
      dispatch(updateSingleCard(cardId));
      dispatch(setCardSending(false));
      dispatch(toggleTreatmentPlan({ isShow: false, treatmentId: null, cardId: null }));
      // dispatch(push(`/profile/${userId}/projects`));
      // dispatch(setCardAdd(true));
    },
    () => {
      dispatch(setCardSending(false));
    },
  );
};

export const updateTreatmentPlan = (cardId, treatmentId, data) => (dispatch, getState) => {
  dispatch(setCardSending(true));
  API.patch(`/treatment_plan/${treatmentId}`, data).then(
    (response) => {
      dispatch(updateSingleCard(cardId));
      dispatch(toggleTreatmentPlan({ isShow: false, treatmentId: null, cardId: null }));
      dispatch(setCardSending(false));
      // dispatch(push(`/profile/${userId}/projects`));
      // dispatch(setCardAdd(true));
    },
    () => {
      dispatch(setCardSending(false));
    },
  );
};

export const createFeedPatient3dSlider = (data, cardId) => (dispatch, getState) => {
  dispatch(setCardSending(true));
  return API.post("/setup_data", data).then(
    (response) => {
      Logger.info("response", response);
      dispatch(toggleStlData({ isShow: false, itemId: null, cardId: null }));
      dispatch(updateSingleCard(cardId));
      dispatch(setCardSending(false));
    },
    (error) => {
      Logger.error(error);
    },
  );
};

export const updateFeedPatient3dSlider = (itemId, data) => (dispatch, getState) => {
  dispatch(setCardSending(true));
  return API.patch(`/setup_data/${itemId}`, data).then(
    (response) => {
      Logger.info("response", response);
      dispatch(setCardSending(false));
      // dispatch(updateSingleCard(cardId));
    },
    (error) => {
      Logger.error(error);
    },
  );
};

// Card preview Data

function setCardPreviewData(data) {
  return {
    type: SET_CARD_PREVIEW_DATA,
    payload: data,
  };
}

export const getCardPreviewData = (cardPreviewId) => (dispatch, getState) => {
  return API.get(`/card/${cardPreviewId}`).then((response) => {
    dispatch(setCardPreviewData(response.data));
  });
};

function setCardPreviewDataList(data) {
  return {
    type: SET_CARD_PREVIEW_DATA_LIST,
    payload: data,
  };
}

export const getCardPreviewDataList = (cardPreviewId) => (dispatch, getState) => {
  API.get(`/card/${cardPreviewId}/objects`).then((response) => {
    dispatch(setCardPreviewDataList(response.data));
  });
};
