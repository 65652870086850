import { API } from "../modules/Api";
import Logger from "../modules/Logger";
import { toggleDeleteVisualization } from "../actions";

export const OBTAIN_SIDEBAR_CONTACTS_DATA = "OBTAIN_SIDEBAR_CONTACTS_DATA";
export const OBTAIN_SIDEBAR_CONTACTS_ERROR = "OBTAIN_SIDEBAR_CONTACTS_ERROR";
export const OBTAIN_CONTACTS_DATA = "OBTAIN_CONTACTS_DATA";
export const OBTAIN_CONTACTS_ERROR = "OBTAIN_CONTACTS_ERROR";
export const USER_NAME = "USER_NAME";

// export const OBTAIN_INVITE_DATA = "OBTAIN_INVITE_DATA";
// export const OBTAIN_INVITE_ERROR = "OBTAIN_INVITE_ERROR";
// export const OBTAIN_TEAM_DATA = "OBTAIN_TEAM_DATA";
// export const OBTAIN_TEAM_DATA_LOADING = "OBTAIN_TEAM_DATA_LOADING";
// export const OBTAIN_TEAM_ERROR = "OBTAIN_TEAM_ERROR";
// export const UNREAD_LIST = "UNREAD_LIST";
// export const UNREAD_LIST_ERROR = "UNREAD_LIST_ERROR";
// export const UNREAD_LIST_CANCEL_TOKEN = "UNREAD_LIST_CANCEL_TOKEN";

function obtainSidebarContactsData(contactsList, nextCursor) {
  return {
    type: "OBTAIN_SIDEBAR_CONTACTS_DATA",
    payload: {
      contactsList: contactsList,
      nextCursor: nextCursor,
    },
  };
}

function obtainSidebarContactsError(errors) {
  return {
    type: "OBTAIN_SIDEBAR_CONTACTS_ERROR",
    payload: {
      errors: errors,
    },
  };
}

export const getSidebarContacts = (page) => (dispatch, getState) => {
  if (page === 1) {
    dispatch(obtainSidebarContactsData([], null));
  }
  const nextCursor = getState().contact.contactsSidebarListNextCursor;
  return API.get("/user/contacts", { params: { page: nextCursor } }).then(
    (response) => {
      const currentCardsList = page !== 1 ? [...getState().contact.contactsSidebarList] : [];
      let contactsList = [...currentCardsList, ...response.data.results];

      dispatch(obtainSidebarContactsData(contactsList, response.data.next));
    },
    (error) => {
      dispatch(obtainSidebarContactsError(error));
    },
  );
};

export const markasreadSidebarContacts = (contactId) => (dispatch, getState) => {
  let contactsList = [
    ...getState().contact.contactsSidebarList.map((el) =>
      el.id === contactId
        ? {
          ...el,
          unread_pm: 0,
        }
        : el,
    ),
  ];
  const nextCursor = getState().contact.contactsSidebarListNextCursor;
  dispatch(obtainSidebarContactsData(contactsList, nextCursor));
};

function obtainContactsData(contactsList, contactsTotal, nextCursor) {
  return {
    type: "OBTAIN_CONTACTS_DATA",
    payload: {
      contactsList: contactsList,
      contactsTotal: contactsTotal,
      nextCursor: nextCursor,
    },
  };
}

function obtainContactsError(errors) {
  return {
    type: "OBTAIN_CONTACTS_ERROR",
    payload: {
      errors: errors,
    },
  };
}

export const getContacts = (page, search = null) => (dispatch, getState) => {
  if (page === 1) {
    dispatch(obtainContactsData([], 0, null));
  }
  const nextCursor = getState().contact.contactsListNextCursor;
  return API.get("/user/contacts", { params: { page: nextCursor, search: search } }).then(
    (response) => {
      let contactsList = [...getState().contact.contactsList].concat(response.data.results);
      dispatch(obtainContactsData(contactsList, response.data.count, response.data.next));
    },
    (error) => {
      Logger.info(error);
      dispatch(obtainContactsError(error));
    },
  );
};

export function userName(name) {
  return {
    type: USER_NAME,
    payload: name,
  };
}

export const deleteContact = (userId) => (dispatch) => {
  return API.delete(`/user/${userId}/delete`).then(() => {
    dispatch(getSidebarContacts(1));
    dispatch(getContacts(1));
    dispatch(toggleDeleteVisualization({ isShow: false, isUserId: null }));
  });
};

// function obtainTeamData(data, nextCursor) {
//   return {
//     type: OBTAIN_TEAM_DATA,
//     payload: { data, nextCursor },
//   };
// }
// function obtainTeamDataLoading(teamListLoading) {
//   return {
//     type: OBTAIN_TEAM_DATA_LOADING,
//     payload: teamListLoading,
//   };
// }

// function obtainTeamError(error) {
//   return {
//     type: OBTAIN_TEAM_ERROR,
//     payload: error,
//   };
// }
// export const clearTeamList = () => dispatch => {
//   dispatch(obtainTeamData([], null));
// };
// export const getTeamList = (page, search = "") => (dispatch, getState) => {
//   dispatch(obtainTeamDataLoading(true));
//   if (page === 1) {
//     dispatch(clearTeamList());
//   }
//   const nextCursor = getState().contact.teamListNextCursor;
//   return API.get("/user/contacts", { params: { cursor: nextCursor, search: search } }).then(
//     response => {
//       Logger.info(response.data);
//       const teamList = [...getState().contact.teamList, ...response.data.results];
//       dispatch(obtainTeamData(teamList, response.data.next));
//       dispatch(obtainTeamDataLoading(false));
//     },
//     error => {
//       Logger.info(error);
//       dispatch(obtainTeamError(error));
//     },
//   );
// };

// export const deleteContact = userId => dispatch => {
//   return API.delete(`/user/${userId}/delete`).then(
//     response => {
//       Logger.info(response.data);
//       dispatch(getTeamList(1));
//       dispatch(toggleDeleteDialog({ isShow: false, isDeleteUserId: null }));
//     },
//     error => {
//       Logger.info(error);
//     },
//   );
// };

// export function unreadPmContacts(data, nextOffset, count) {
//   return {
//     type: UNREAD_LIST,
//     payload: { data, nextOffset, count },
//   };
// }

// function unreadPmError(error) {
//   return {
//     type: UNREAD_LIST_ERROR,
//     payload: error,
//   };
// }

// export const clearPmContacts = () => dispatch => {
//   dispatch(unreadPmContacts([], null));
// };

// function unreadPmContactsCancelToken(token) {
//   return {
//     type: UNREAD_LIST_CANCEL_TOKEN,
//     payload: token,
//   };
// }

// export const getPmContactSocket = userId => (dispatch, getState) => {
//   const unread_list = [...getState().contact.unread_list];
//   const resetPMCounterUserList = unread_list.map(el => (el.id === parseInt(userId, 10) ? { ...el, unread_pm: 0 } : el));
//   dispatch(unreadPmContacts(resetPMCounterUserList, null, null));
//   // return API.get("/user/pm/contacts", {
//   //   params: {
//   //     limit: 16,
//   //   },
//   // }).then(
//   //   response => {
//   //     const unread_list = response.data.results;
//   //     console.log("UNDER UNDER UNDER PM", unread_list);
//   //     const sideUserList = unread_list.map(el => (el.id === userId ? { ...el, unread_pm: 0 } : el));
//   //     dispatch(unreadPmContacts(unread_list, response.data.next, response.data.count));
//   //   },
//   //   error => {
//   //     Logger.info(error);
//   //     dispatch(unreadPmError(error));
//   //   },
//   // );
// };
