import { CancelToken } from "axios";
import moment from "moment";
import { toggleDeleteAppointment } from "../actions";
import { API } from "../modules/Api.js";
import Logger from "../modules/Logger";
import { uniqBy } from "lodash";
export const OBTAIN_APPOINTMENTS_DATA = "OBTAIN_APPOINTMENTS_DATA";
export const APPOINTMENTS_CARD_DATA = "APPOINTMENTS_CARD_DATA";
export const OBTAIN_APPOINTMENTS_ERROR = "OBTAIN_APPOINTMENTS_ERROR";
export const TOGGLE_APPOINTMENT_DIALOG = "TOGGLE_APPOINTMENT_DIALOG";
export const OBTAIN_APPOINTMENTS_LOADING = "OBTAIN_APPOINTMENTS_LOADING";
export const OBTAIN_APPOINTMENTS_SEARCH_CANCEL_TOKEN = "OBTAIN_APPOINTMENTS_SEARCH_CANCEL_TOKEN";

export function toggleAppointmentDialog({ isShow, appointmentId, cardId }) {
  return {
    type: TOGGLE_APPOINTMENT_DIALOG,
    payload: { isShow, cardId, appointmentId },
  };
}

function obtainAppointmentsData(data, nextCursor, query) {
  return {
    type: OBTAIN_APPOINTMENTS_DATA,
    payload: { data, nextCursor, query },
  };
}

function appointmentsCardData(data) {
  return {
    type: APPOINTMENTS_CARD_DATA,
    payload: { data },
  };
}

function obtainAppointmentsError(error) {
  return {
    type: OBTAIN_APPOINTMENTS_ERROR,
    payload: error,
  };
}

function obtainAppointmentsLoading(appointmentsListLoading) {
  return {
    type: OBTAIN_APPOINTMENTS_LOADING,
    payload: appointmentsListLoading,
  };
}

function obtainAppointmentsCancelToken(token) {
  return {
    type: OBTAIN_APPOINTMENTS_SEARCH_CANCEL_TOKEN,
    payload: token,
  };
}

export const getSingleAppointment = (id) => (dispatch, getState) => {
  const requestQuery = getState().appointments.appointmentsListQuery;
  // If we does'nt have query we does not have list data
  if (!requestQuery) {
    console.warn("empty requerst query");
    return false;
  }
  return API.get("/appointment", { params: { ...requestQuery, appointment_id: id } }).then((response) => {
    const currentAppointmentsList = [
      ...getState().appointments.appointmentsList.filter((el) => el.id !== id),
      ...response.data.results,
    ];

    // Keep cursor
    const newNextCursor = requestQuery.cursor ? requestQuery.cursor : null;
    dispatch(obtainAppointmentsData(currentAppointmentsList, newNextCursor, requestQuery));
  });
};

export const getAppointments = (query, firstLoad = false) => (dispatch, getState) => {
  const currentCancelToken = getState().appointments.appointmentsListCancelToken;
  if (currentCancelToken) {
    currentCancelToken();
  }

  dispatch(obtainAppointmentsLoading(true));

  if (firstLoad) {
    dispatch(obtainAppointmentsData([], null, null));
  }

  let cursorNext = getState().appointments.appointmentsListNextCursor;
  let requestQuery = {
    exact_date: query.date ? moment(query.date).format("DD.MM.YYYY") : null,
    cursor: cursorNext,
    doctor_name: query.user,
    patient_name: query.description,
  };

  return API.get("/appointment", {
    cancelToken: new CancelToken((c) => {
      dispatch(obtainAppointmentsCancelToken(c));
    }),
    params: requestQuery,
  }).then(
    (response) => {
      Logger.info(response);
      const currentAppointmentsList = uniqBy(
        [...getState().appointments.appointmentsList, ...response.data.results],
        "id",
      );

      const newNextCursor = response.data.next;
      requestQuery.cursor = newNextCursor;

      dispatch(obtainAppointmentsData(currentAppointmentsList, newNextCursor, requestQuery));
      dispatch(obtainAppointmentsLoading(false));
    },
    (error) => {
      Logger.info(error);
      dispatch(obtainAppointmentsError(error));
    },
  );
};

export const getAppointmentInCard = (cardId) => (dispatch) => {
  return API.get("/appointment", { params: { card_id: cardId } }).then(
    (response) => {
      // Logger.info("response", response);
      dispatch(appointmentsCardData(response.data.results));
    },
    (error) => {
      Logger.info(error);
      // dispatch(obtainAppointmentsError(error));
    },
  );
};

export const createAppointment = (data) => (dispatch) => {
  return API.post("/appointment", data).then(
    (response) => {
      // Logger.info("response create appoint", response);
      dispatch(toggleAppointmentDialog({ isShow: false, appointmentId: null, cardId: null }));
    },
    (error) => {
      Logger.info(error);
      // dispatch(obtainAppointmentsError(error));
    },
  );
};

// TODO
export const updateAppointment = (appointmentId, data) => (dispatch) => {
  return API.patch(`/appointment/${appointmentId}`, data).then(
    (response) => {
      // Logger.info("response appoint edit", response);
      dispatch(toggleAppointmentDialog({ isShow: false, appointmentId: null, cardId: null }));
    },
    (error) => {
      Logger.info(error);
      // dispatch(obtainAppointmentsError(error));
    },
  );
};

// // TODO
export const deleteAppointment = (appointmentId, cardId) => (dispatch, getState) => {
  return API.delete(`/appointment/${appointmentId}`).then(
    (response) => {
      // Logger.info("response", response);
      dispatch(toggleDeleteAppointment({ isShow: false, isAppointmentId: null }));
      dispatch(getAppointmentInCard(cardId));
    },
    (error) => {
      Logger.info(error);
      // dispatch(obtainAppointmentsError(error));
    },
  );
};
