import {
  TOGGLE_CHATWINDOW,
  SET_CHATBOX_USER,
  START_GET_CHATBOX_MESSAGES,
  OBTAIN_CHATBOX_MESSAGES_DATA,
  OBTAIN_CHATBOX_MESSAGES_ERROR,
} from "../actions";

const initialState = {
  isChatWindow: false,
  isShow: false,
  currentUser: null,
  isLoading: false,
  messageList: [],
  nextCursor: null,
  errors: [],
};

export default function chatBoxReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_CHATWINDOW:
      return { ...state, isChatWindow: action.payload.isChatWindow };
    case SET_CHATBOX_USER:
      return {
        ...state,
        currentUser: action.payload,
        isLoading: false,
        messageList: [],
        nextCursor: null,
        errors: [],
      };
    case START_GET_CHATBOX_MESSAGES:
      return { ...state, isLoading: true };
    case OBTAIN_CHATBOX_MESSAGES_DATA:
      return {
        ...state,
        isLoading: false,
        messageList: action.payload.data,
        nextCursor: action.payload.nextCursor,
        errors: [],
      };
    case OBTAIN_CHATBOX_MESSAGES_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
}
