import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import i18next from "../../i18n";
import { useLocation } from "react-router";
import metaList from "../../metaList";

const MetaTags = ({ children }) => {
  const location = useLocation();
  const path = location.pathname;
  const currentLang = i18next.language;

  let base_url = useMemo(() => {
    const base_urls = { en: "https://meshalign.com", ru: "https://ru.meshalign.com" };
    if (base_urls.hasOwnProperty(currentLang)) {
      return base_urls[currentLang];
    }
    return base_urls["en"];
  }, [currentLang]);

  const tags = useMemo(() => {
    if (metaList.hasOwnProperty(path) && metaList[path].hasOwnProperty(currentLang)) {
      return metaList[path][currentLang];
    }
    return metaList["/"]["en"];
  }, [currentLang, path]);

  const locale = useMemo(() => {
    const localesList = { en: "en_EN", ru: "ru_RU" };
    if (localesList.hasOwnProperty(currentLang)) {
      return localesList[currentLang];
    }
    return localesList["en"];
  }, [currentLang]);

  return (
    <Helmet>
      <title>{`MeshAlign | ${tags.title}`}</title>
      <meta name="description" content={tags.desc} />
      <meta name="keywords" content={tags.keys} />
      <meta property="og:locale" content={locale} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={`MeshAlign | ${tags.title}`} />
      <meta property="og:description" content={tags.desc} />
      <meta property="og:image" content={`${base_url}${tags.img}`} />
      <meta property="og:url" content={`${base_url}/${tags.url}/`} />
      <meta property="og:url:alternate" content={`https://meshalign.com${tags.url}/`} />
      <meta property="og:url:alternate" content={`https://ru.meshalign.com${tags.url}/`} />
      {/*<meta property="og:site_name" content={tags.locale} />*/}
      <meta name="robots" content={tags.hasOwnProperty("robots") ? tags.robots : "index, follow"} />
      <link rel="canonical" href={`${base_url}/${tags.url}/`} />
      <link rel="alternate" hrefLang="ru" href={`https://meshalign.com${tags.url}/`} />
      <link rel="alternate" hrefLang="ru" href={`https://ru.meshalign.com${tags.url}/`} />
      {children}
    </Helmet>
  );
};

export default MetaTags;
