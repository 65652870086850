import { API } from "../modules/Api.js";
import { setAuth } from "./loginAction.js";
import { changeChatBoxUser } from "./chatAction";
import { setCardAdd } from "./snackbarNotificationAction";

export const START_OBTAIN_PROFILE_DATA = "START_OBTAIN_PROFILE_DATA";
export const OBTAIN_PROFILE_DATA = "OBTAIN_PROFILE_DATA";
export const OBTAIN_PROFILE_ERROR = "OBTAIN_PROFILE_ERROR";

function startObtainProfileData() {
  return {
    type: START_OBTAIN_PROFILE_DATA,
  };
}

function obtainProfileData(data) {
  return {
    type: OBTAIN_PROFILE_DATA,
    payload: data,
  };
}

function obtainProfileError(error) {
  return {
    type: OBTAIN_PROFILE_ERROR,
    payload: error,
  };
}

// Get profile
export const getProfile = () => (dispatch) => {
  dispatch(startObtainProfileData());
  return API.get("auth/profile").then(
    (response) => {
      dispatch(setAuth());
      const profile = {
        id: response.data.id,
        account_type: response.data.account_type,
        email: response.data.email,
        avatar: response.data.avatar,
        name: response.data.name,
        clinic: response.data.clinic,
        phone_number: response.data.phone_number,
        additional_phone_number: response.data.additional_phone_number,
        country: response.data.country,
        address: response.data.address,
        language: response.data.language,
        theme: response.data.theme,
        send_email_notifications: response.data.send_email_notifications,
      };
      dispatch(obtainProfileData(profile));
      if (profile.account_type !== "owner") {
        dispatch(changeChatBoxUser(4));
      }
    },
    (error) => {
      dispatch(setAuth(true));
      dispatch(obtainProfileError(error));
    },
  );
};

// Update profile
export const updateProfile = ({ name, clinic, phone_number, country, language, send_email_notifications }) => (
  dispatch,
) => {
  return API.put("auth/profile", {
    name,
    clinic,
    phone_number,
    country,
    language,
    send_email_notifications,
  }).then(
    (response) => {
      dispatch(obtainProfileData(response.data));
      dispatch(setCardAdd(true));
    },
    (error) => {
      dispatch(obtainProfileError(error));
    },
  );
};

export const updateProfileOwner = ({
  phone_number,
  additional_phone_number,
  address,
  language,
  send_email_notifications,
}) => (dispatch) => {
  return API.put("auth/profile", {
    phone_number,
    additional_phone_number,
    address,
    language,
    send_email_notifications,
  }).then(
    (response) => {
      dispatch(obtainProfileData(response.data));
      dispatch(setCardAdd(true));
    },
    (error) => {
      dispatch(obtainProfileError(error));
    },
  );
};

export const switchTopBar = (theme) => (dispatch) => {
  return API.put("auth/profile", {
    theme,
  }).then((response) => {
    dispatch(obtainProfileData(response.data));
  });
};
